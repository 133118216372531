import Papa from "papaparse";

export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so we add 1
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const formatDateInDb = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so we add 1
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const formatStringDate = (dateString) => {
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

export const formatDateInNumber = (date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();

  // Add leading zero if month or day is less than 10
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedDay = day < 10 ? `0${day}` : `${day}`;

  return `${formattedMonth}${formattedDay}${year}`;
};

const formatDbDate = (datetimeString) => {
  // Assuming datetimeString is in the format "YYYY-MM-DD HH:mm:ss"
  const dateOnly = datetimeString.split(" ")[0];
  return dateOnly;
};

export const timeStampToDate = (value) => {
  const milliseconds = value * 1000;
  const date = new Date(milliseconds);

  // Get various date components
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
  const day = date.getDate();
  return `${month}/${day}/${year}`;
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

export const exportCSV = (data) => {
  try {
    // Map the data to only include UPC, Title, and Price columns
    const csvData = data.map((record) => [
      record.scan_id,
      record.title,
      record.description,
      record.price,
      record.qty,
    ]);

    const headers = ["UPC", "Title", "Description", "Price", "Qty"];

    // Add the headers as the first row
    csvData.unshift(headers);

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "product_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert("Your browser does not support downloading CSV files.");
    }
  } catch (error) {
    console.error("Error exporting CSV:", error);
    alert("An error occurred while exporting CSV.");
  }
};

export const exportUsers = (data) => {
  try {
    // Map the data to only include UPC, Title, and Price columns
    const csvData = data.map((record, index) => [
      index + 1,
      record.name,
      record.email,
      record.plan,
      record.created_at,
    ]);

    const headers = ["Id", "Name", "Email", "Plan", "Created At"];

    // Add the headers as the first row
    csvData.unshift(headers);

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "users_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert("Your browser does not support downloading CSV files.");
    }
  } catch (error) {
    console.error("Error exporting CSV:", error);
    alert("An error occurred while exporting CSV.");
  }
};

export const calculateDiscountedPrice = (originalPrice, discount) => {
  const discountPercentage = parseFloat(discount);
  const discountAmount = (discountPercentage / 100) * originalPrice;
  const finalPrice = originalPrice - discountAmount;
  return finalPrice.toFixed(2);
};

export const currentDateInFormat = () => {
  // Get the current date
  const currentDate = new Date();
  const formattedDate = `${(currentDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${currentDate
    .getDate()
    .toString()
    .padStart(2, "0")}-${currentDate.getFullYear()}`;
  return formattedDate;
};

export const categories = [
  "Action Figures",
  "Antiques, Vintage & Ephemera",
  "Arts & Handmade",
  "Bags & Accessories",
  "Beauty",
  "Books",
  "Candles & Aromatherapy",
  "Car Parts",
  "Coins & Money",
  "Comics & Manga",
  "Disneyana",
  "Electronics",
  "Estate Sales & Storage Units",
  "Food & Drink",
  "Home & Garden",
  "Jewelry",
  "Kawaii",
  "Knives & Hunting",
  "Baby & Kids",
  "Men's Fashion",
  "Movies",
  "Music",
  "Pet Supplies",
  "NFTs",
  "Rocks & Crystals",
  "Sneakers & Streetwear",
  "Sports Cards",
  "Sporting Gear",
  "Toys & Hobbies",
  "Trading Card Games",
  "Video Games",
  "Watches",
  "Women's Fashion",
  "and Whatnot",
];

export const hazmat = [
  "Not Hazmat",
  "Hazmat - Standard",
  "Hazmat - Lithium Battery",
];

export const discountOptions = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90];

export const type = ["Auction", "Buy It Now", "Giveaway"];

export const auctionType = [
  { label: "Auction", value: "Auction" },
  { label: "Buy It Now", value: "Fixed Price" },
];

export const descriptionTags = [
  "New",
  "Used",
  "As-Is",
  "Untested",
  "Open-Box",
  "Damaged",
];
// export const conditionValues = {
//   "Action Figures": ["Mint", "Near Mint", "Good", "Fair", "Poor"],
//   "Akora": [
//     "Graded",
//     "New",
//     "Mint",
//     "Near Mint",
//     "Light Played",
//     "Moderately Played",
//     "Heavily Played",
//     "Damaged",
//   ],
//   "Anime & Manga": [
//     "Graded",
//     "Gem Mint",
//     "Mint",
//     "Near Mint",
//     "Very Fine",
//     "Fine",
//     "Very Good",
//     "Good",
//     "Fair",
//     "Poor",
//     "Mint",
//     "Near Mint",
//     "Good",
//     "Fair",
//     "Poor",
//   ],
//   "Antiques": [
//     "Pre-owned - Excellent",
//     "Pre-owned - Good",
//     "Pre-owned - Fair",
//     "Pre-owned - Damaged",
//   ],
//   "Antiques, Vintage & Ephemera": [
//     "Pre-owned - Excellent",
//     "Pre-owned - Good",
//     "Pre-owned - Fair",
//     "Pre-owned - Damaged",
//   ],
//   "Art & Prints": [
//     "Brand New",
//     "Like New",
//     "Very Good",
//     "Good",
//     "Fair",
//     "Poor",
//   ],
//   "Baby & Kids": [
//     "New With Tags",
//     "New without Tags",
//     "New with Defects",
//     "Pre-owned - Excellent",
//     "Pre-owned - Good",
//     "Pre-owned - Fair",
//     "Pre-owned - Damaged",
//   ],
//   "Baseball Singles": [
//     "Graded",
//     "Raw - Near Mint or Better",
//     "Raw - Excellent",
//     "Raw - Very Good",
//     "Raw - Poor",
//     "Graded",
//     "Raw - Near Mint or Better",
//     "Raw - Excellent",
//     "Raw - Very Good",
//     "Raw - Poor",
//   ],
//   "Beading & Jewelry Making Supplies": [
//     "New",
//     "Pre-owned - Excellent",
//     "Pre-owned - Good",
//     "Pre-owned - Fair",
//     "Pre-owned - Damaged",
//   ],
//   "Beanie Babies & Boos": ["New", "Used"],
//   "Blu-ray": ["Brand New", "Like New", "Very Good", "Good", "Fair", "Poor"],
//   "Books": ["Brand New", "Like New", "Very Good", "Good", "Fair", "Poor"],
//   "CDs & Cassettes": [
//     "Mint (M)",
//     "Near mint (NM)",
//     "Excellent (EX)",
//     "Very Good Plus (VG+)",
//     "Very Good (VG), Good Plus (G+)",
//     "Good (G)",
//     "Poor (P)",
//     "Fair (F)",
//   ],
//   "Cameras & Photography": [
//     "Brand New",
//     "Open-box",
//     "Refurbished",
//     "Used and fully functioning",
//     "For parts, not fully functioning, or untested",
//   ],
//   "Coins & Bullion": [
//     "Mint State",
//     "Uncirculated",
//     "Very Fine",
//     "Fine",
//     "Good",
//     "Poor",
//   ],
//   "Comic Art": [
//     "Graded",
//     "Gem Mint",
//     "Mint",
//     "Near Mint",
//     "Very Fine",
//     "Fine",
//     "Very Good",
//     "Good",
//     "Fair",
//     "Poor",
//   ],
//   "Consoles & Accessories": [
//     "Brand New, Factory Sealed",
//     "Open Box",
//     "Refurbished",
//     "Used",
//     "For Parts or Not Working",
//   ],
//   "Contemporary Costume": [
//     "New",
//     "New with defects",
//     "Pre-owned - Excellent",
//     "Pre-owned - Good",
//     "Pre-owned - Fair",
//     "Pre-owned - Damaged",
//   ],
//   "DC Cards": [
//     "Graded",
//     "New",
//     "Mint",
//     "Near Mint",
//     "Light Played",
//     "Moderately Played",
//     "Heavily Played",
//     "Damaged",
//   ],
//   "DC Figures": [
//     "Mint",
//     "Near Mint",
//     "Good",
//     "Fair",
//     "Poor",
//   ],
//   "DVDs": [
//     "Brand New",
//     "Like New",
//     "Very Good",
//     "Good",
//     "Fair",
//     "Poor",
//   ],
//   "Diecast Cars": [
//     "Mint",
//     "Near Mint",
//     "Good",
//     "Fair",
//     "Poor",
//   ],
//   "Digimon Cards": [
//     "Graded",
//     "New",
//     "Mint",
//     "Near Mint",
//     "Light Played",
//     "Moderately Played",
//     "Heavily Played",
//     "Damaged",
//   ],
//   "Disney Cards": [
//     "Graded",
//     "New",
//     "Mint",
//     "Near Mint",
//     "Light Played",
//     "Moderately Played",
//     "Heavily Played",
//     "Damaged",
//   ],
//   "Disney Pins": ["New", "Used"],
//   "Disney Plush": [
//     "New",
//     "Used",
//   ],
//   "Dragon Ball Cards": [
//     "Graded",
//     "Raw - Near Mint or Better",
//     "Raw - Excellent",
//     "Raw - Very Good",
//     "Raw - Poor",
//   ],
//   "Another Beanie": [
//     "Mint",
//     "Near Mint",
//     "Good",
//     "Fair",
//     "Poor",
//   ],
//   "Beanie Updated": [
//     "Mint",
//     "Excellent",
//     "Very Good",
//     "Good, Fair",
//     "Poor",
//   ],
//   "Graded Mint": [
//     "Graded",
//     "New",
//     "Mint",
//     "Near Mint",
//     "Light Played",
//     "Moderately Played",
//     "Heavily Played",
//     "Damaged",
//   ],
//   "New With Box": [
//     "New with box",
//     "New without box",
//     "New with defects",
//     "Pre-owned - Good",
//     "Pre-owned - Fair",
//     "Pre-owned - Damaged",
//   ],
//   "New With Tags or Used": [
//     "New with box",
//     "New without box",
//     "New with defects",
//     "Pre-owned - Good",
//     "Pre-owned - Fair",
//     "Pre-owned - Damaged",
//   ],
//   "New Defects": [
//     "New with tags",
//     "Excellent",
//     "Very Good",
//     "Good",
//     "Fair",
//     "Poor",
//   ],
//   "Graded Condition": [
//     "Graded",
//     "Gem Mint",
//     "Mint",
//     "Near Mint",
//     "Very Fine",
//     "Fine",
//     "Very Good",
//     "Good",
//     "Fair",
//     "Poor",
//   ],
//   "Graded Art": [
//     "Brand New, Factory Sealed",
//     "Complete in Box",
//     "Missing Manual",
//     "Loose",
//     "Graded",
//   ],
//   "Updated Condition": [
//     "Mint",
//     "Excellent",
//     "Very Good",
//     "Good",
//     "Fair",
//     "Poor",
//     "Mint",
//     "Excellent",
//     "Very Good",
//     "Good",
//     "Fair",
//     "Poor",
//   ],
//   "Final Graded": [
//     "Graded",
//     "New",
//     "Mint",
//     "Near Mint",
//     "Light Played",
//     "Moderately Played",
//     "Heavily Played",
//     "Damaged",
//   ],
//   "Final New Graded": [
//     "Graded",
//     "New",
//     "Mint",
//     "Near Mint",
//     "Light Played",
//     "Moderately Played",
//     "Heavily Played",
//     "Damaged",
//   ],
//   "Final Condition Updated": [
//     "Brand New",
//     "Like New",
//     "Very Good",
//     "Good",
//     "Fair",
//     "Poor",
//   ],
// };
export const getConditions=(category)=>{
 const conditionValues ={
  "Action Figures": [
      "Mint",
      "Near Mint",
      "Good",
      "Fair",
      "Poor"
  ],
  "Akora": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "Anime & Manga": [
      "Graded",
      "Gem Mint",
      "Mint",
      "Near Mint",
      "Very Fine",
      "Fine",
      "Very Good",
      "Good",
      "Fair",
      "Poor"
  ],
  "Anime Figures": [
      "Mint",
      "Near Mint",
      "Good",
      "Fair",
      "Poor"
  ],
  "Antiques": [
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Antiques, Vintage & Ephemera": [
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Art & Prints": [
      "Brand New",
      "Like New",
      "Very Good",
      "Good",
      "Fair",
      "Poor"
  ],
  "Baby & Kids": [
      "New With Tags",
      "New without Tags",
      "New with Defects",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Baseball Singles": [
      "Graded",
      "Raw - Near Mint or Better",
      "Raw - Excellent",
      "Raw - Very Good",
      "Raw - Poor"
  ],
  "Basketball Singles": [
      "Graded",
      "Raw - Near Mint or Better",
      "Raw - Excellent",
      "Raw - Very Good",
      "Raw - Poor"
  ],
  "Beading & Jewelry Making Supplies": [
      "New",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Beanie Babies & Boos": [
      "New",
      "Used"
  ],
  "Blu-ray": [
      "Brand New",
      "Like New",
      "Very Good",
      "Good",
      "Fair",
      "Poor"
  ],
  "Books": [
      "Brand New",
      "Like New",
      "Very Good",
      "Good",
      "Fair",
      "Poor"
  ],
  "CDs & Cassettes": [
      "Mint (M)",
      "Near mint (NM)",
      "Excellent (EX)",
      "Very Good Plus (VG+)",
      "Very Good (VG), Good Plus (G+)",
      "Good (G)",
      "Poor (P)",
      "Fair (F)"
  ],
  "Cameras & Photography": [
      "Brand New",
      "Open-box",
      "Refurbished",
      "Used and fully functioning",
      "For parts, not fully functioning, or untested"
  ],
  "Coins & Bullion": [
      "Mint State",
      "Uncirculated",
      "Very Fine",
      "Fine",
      "Good",
      "Poor"
  ],
  "Comic Art": [
      "Graded",
      "Gem Mint",
      "Mint",
      "Near Mint",
      "Very Fine",
      "Fine",
      "Very Good",
      "Good",
      "Fair",
      "Poor"
  ],
  "Consoles & Accessories": [
      "Brand New, Factory Sealed",
      "Open Box",
      "Refurbished",
      "Used",
      "For Parts or Not Working"
  ],
  "Contemporary Costume": [
      "New",
      "New with defects",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "DC Cards": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "DC Figures": [
      "Mint",
      "Near Mint",
      "Good",
      "Fair",
      "Poor"
  ],
  "DVDs": [
      "Brand New",
      "Like New",
      "Very Good",
      "Good",
      "Fair",
      "Poor"
  ],
  "Diecast Cars": [
      "Mint",
      "Near Mint",
      "Good",
      "Fair",
      "Poor"
  ],
  "Digimon Cards": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "Disney Cards": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "Disney Pins": [
      "New",
      "Used"
  ],
  "Disney Plush": [
      "New",
      "Used"
  ],
  "Dragon Ball Cards": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "Electronics": [
      "Brand New",
      "Open-box",
      "Refurbished",
      "Used and fully functioning",
      "For parts, not fully functioning, or untested"
  ],
  "Ephemera & Postcards": [
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Everyday Electronics": [
      "Brand New",
      "Open-box",
      "Refurbished",
      "Used and fully functioning",
      "For parts, not fully functioning, or untested"
  ],
  "Fiber Arts & Sewing Supplies": [
      "New",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Fine & Precious Metals": [
      "New",
      "New with defects",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Flesh & Blood": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "Football Singles": [
      "Graded",
      "Raw - Near Mint or Better",
      "Raw - Excellent",
      "Raw - Very Good",
      "Raw - Poor"
  ],
  "Funko Pop!": [
      "Mint",
      "Near Mint",
      "Good",
      "Fair",
      "Poor"
  ],
  "Funko Soda": [
      "Mint",
      "Near Mint",
      "Good",
      "Fair",
      "Poor"
  ],
  "G.I. Joe Figures": [
      "Mint",
      "Near Mint",
      "Good",
      "Fair",
      "Poor"
  ],
  "Garbage Pail Kids": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "Golf Accessories": [
      "New",
      "Used"
  ],
  "Golf Apparel": [
      "New",
      "Used"
  ],
  "Golf Bags": [
      "New",
      "Used"
  ],
  "Golf Balls": [
      "New",
      "Used"
  ],
  "Golf Clubs": [
      "New",
      "Used"
  ],
  "Golf Shoes": [
      "New",
      "Used"
  ],
  "Graphic Novels": [
      "Brand New",
      "Like New",
      "Very Good",
      "Good",
      "Fair",
      "Poor"
  ],
  "Handcrafted & Artisan Jewelry": [
      "New",
      "New with defects",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Hockey Singles": [
      "Graded",
      "Raw - Near Mint or Better",
      "Raw - Excellent",
      "Raw - Very Good",
      "Raw - Poor"
  ],
  "Home Decor": [
      "New with box",
      "New without box",
      "New with defects",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Hot Toys": [
      "Mint",
      "Near Mint",
      "Good",
      "Fair",
      "Poor"
  ],
  "Instruments & Accessories": [
      "Mint",
      "Excellent",
      "Very Good",
      "Good, Fair",
      "Poor"
  ],
  "Jewelry": [
      "New",
      "New with defects",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Kickstarter & Other Cards": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "Kitchen & Dining": [
      "New with box",
      "New without box",
      "New with defects",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Knives & Hunting": [
      "New with box",
      "New without box",
      "New with defects",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Kryptik": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "LEGO Minifigures": [
      "New in box",
      "New with damaged box",
      "New without box",
      "Used - Like New",
      "Used - Good",
      "Used - Fair",
      "Used - Poor"
  ],
  "LEGO Sets": [
      "New in box",
      "New with damaged box",
      "New without box",
      "Used - Like New",
      "Used - Good",
      "Used - Fair",
      "Used - Poor"
  ],
  "Lures": [
      "New",
      "pre-owned - Excellent",
      "pre-owned - good",
      "pre-owned - fair",
      "pre-owned - damaged"
  ],
  "Luxury Bags & Accessories": [
      "New With Tags",
      "Excellent",
      "Very Good",
      "Good",
      "Fair",
      "Poor"
  ],
  "MOTU Figures": [
      "Mint",
      "Near Mint",
      "Good",
      "Fair",
      "Poor"
  ],
  "Magazines": [
      "Brand New",
      "Like New",
      "Very Good",
      "Good",
      "Fair",
      "Poor"
  ],
  "Magic: The Gathering": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "Marvel Cards": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "Marvel Figures": [
      "Mint",
      "Near Mint",
      "Good",
      "Fair",
      "Poor"
  ],
  "Men's Fashion": [
      "New With Tags",
      "New without Tags",
      "New with Defects",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Men's Jewelry": [
      "New",
      "New with defects",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Men's Modern": [
      "New With Tags",
      "New without Tags",
      "New with Defects",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Men's Vintage Clothing": [
      "New With Tags",
      "New without Tags",
      "New with Defects",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "MetaZoo": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "Midrange & Fashion Bags": [
      "New With Tags",
      "Excellent",
      "Very Good",
      "Good",
      "Fair",
      "Poor"
  ],
  "Modern Comics": [
      "Graded",
      "Gem Mint",
      "Mint",
      "Near Mint",
      "Very Fine",
      "Fine",
      "Very Good",
      "Good",
      "Fair",
      "Poor"
  ],
  "Modern Games": [
      "Brand New, Factory Sealed",
      "Complete in Box",
      "Missing Manual",
      "Loose",
      "Graded"
  ],
  "Movie Memorabilia": [
      "Mint",
      "Excellent",
      "Very Good",
      "Good",
      "Fair",
      "Poor"
  ],
  "Music Memorabilia": [
      "Mint",
      "Excellent",
      "Very Good",
      "Good, Fair",
      "Poor"
  ],
  "My Hero Academia Cards": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "Naruto Cards": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "New & Used Books": [
      "Brand New",
      "Like New",
      "Very Good",
      "Good",
      "Fair",
      "Poor"
  ],
  "One Piece Cards": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "Other Action Figures": [
      "Mint",
      "Near Mint",
      "Good",
      "Fair",
      "Poor"
  ],
  "Other Craft Supplies": [
      "New",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Other Diecast": [
      "Mint",
      "Near Mint",
      "Good",
      "Fair",
      "Poor"
  ],
  "Other Electronics": [
      "Brand New",
      "Open-box",
      "Refurbished",
      "Used and fully functioning",
      "For parts, not fully functioning, or untested"
  ],
  "Other Fishing Equipment": [
      "New",
      "pre-owned - Excellent",
      "pre-owned - good",
      "pre-owned - fair",
      "pre-owned - damaged"
  ],
  "Other Funko Products": [
      "Mint",
      "Near Mint",
      "Good",
      "Fair",
      "Poor"
  ],
  "Other Home & Garden": [
      "New with box",
      "New without box",
      "New with defects",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Other LEGO": [
      "New in box",
      "New with damaged box",
      "New without box",
      "Used - Like New",
      "Used - Good",
      "Used - Fair",
      "Used - Poor"
  ],
  "Other Men's Fashion": [
      "New With Tags",
      "New without Tags",
      "New with Defects",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Other Plush": [
      "New",
      "Used"
  ],
  "Other Sports Cards": [
      "Graded",
      "Raw - Near Mint or Better",
      "Raw - Excellent",
      "Raw - Very Good",
      "Raw - Poor"
  ],
  "Other Women's Fashion": [
      "New With Tags",
      "New without Tags",
      "New with Defects",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Outdoor Equipment": [
      "New",
      "pre-owned - Excellent",
      "pre-owned - good",
      "pre-owned - fair",
      "pre-owned - damaged"
  ],
  "Paper Money & Currency": [
      "Gem Uncirculated",
      "Uncirculated",
      "Very Fine",
      "Fine",
      "Good",
      "Poor"
  ],
  "Pokémon Cards": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "Pokémon Plush": [
      "New",
      "Used"
  ],
  "Pop Culture Memorabilia": [
      "Graded",
      "Gem Mint",
      "Mint",
      "Near Mint",
      "Very Fine",
      "Fine",
      "Very Good",
      "Good",
      "Fair",
      "Poor"
  ],
  "Power Rangers": [
      "Mint",
      "Near Mint",
      "Good",
      "Fair",
      "Poor"
  ],
  "Rare & Vintage Books": [
      "Brand New",
      "Like New",
      "Very Good",
      "Good",
      "Fair",
      "Poor"
  ],
  "Reels": [
      "New",
      "pre-owned - Excellent",
      "pre-owned - good",
      "pre-owned - fair",
      "pre-owned - damaged"
  ],
  "Retro Games": [
      "Brand New, Factory Sealed",
      "Complete in Box",
      "Missing Manual",
      "Loose",
      "Graded"
  ],
  "Rods": [
      "New",
      "pre-owned - Excellent",
      "pre-owned - good",
      "pre-owned - fair",
      "pre-owned - damaged"
  ],
  "Simpsons Figures": [
      "Mint",
      "Near Mint",
      "Good",
      "Fair",
      "Poor"
  ],
  "Sneakers": [
      "New",
      "New With Defects",
      "Used",
      "Not Specified"
  ],
  "Soccer Singles": [
      "Graded",
      "Raw - Near Mint or Better",
      "Raw - Excellent",
      "Raw - Very Good",
      "Raw - Poor"
  ],
  "Sorcery: Contested Realm": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "Squishmallows": [
      "New",
      "Used"
  ],
  "Stamps": [
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Star Wars Cards": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "Star Wars Figures": [
      "Mint",
      "Near Mint",
      "Good",
      "Fair",
      "Poor"
  ],
  "Strategy Guides, Manuals, Replacement Cases": [
      "Brand New",
      "Like New",
      "Very Good",
      "Good",
      "Acceptable"
  ],
  "Streetwear": [
      "New With Tags",
      "New without Tags",
      "New with Defects",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "TCG Accessories": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "TMNT Figures": [
      "Mint",
      "Near Mint",
      "Good",
      "Fair",
      "Poor"
  ],
  "Tools": [
      "New with box",
      "New without box",
      "New with defects",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Trading Card Games": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "Transformers Figures": [
      "Mint",
      "Near Mint",
      "Good",
      "Fair",
      "Poor"
  ],
  "UFC Singles": [
      "Graded",
      "Raw - Near Mint or Better",
      "Raw - Excellent",
      "Raw - Very Good",
      "Raw - Poor"
  ],
  "Union Arena": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "VHS": [
      "Brand New",
      "Like New",
      "Very Good",
      "Good",
      "Fair",
      "Poor"
  ],
  "VeeFriends": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "Vintage & Antique Jewelry": [
      "New",
      "New with defects",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Vintage Comics": [
      "Graded",
      "Gem Mint",
      "Mint",
      "Near Mint",
      "Very Fine",
      "Fine",
      "Very Good",
      "Good",
      "Fair",
      "Poor"
  ],
  "Vintage Decor": [
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Vinyl Records": [
      "Mint (M)",
      "Near Mint (NM or M-)",
      "Excellent (EX)",
      "Very Good Plus (VG+)",
      "Very Good (VG)",
      "Good Plus (G+)",
      "Good (G)",
      "Fair (F)"
  ],
  "Weiß Schwarz": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ],
  "Women's Activewear": [
      "New With Tags",
      "New without Tags",
      "New with Defects",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Women's Contemporary": [
      "New With Tags",
      "New without Tags",
      "New with Defects",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Women's Fashion": [
      "New With Tags",
      "New without Tags",
      "New with Defects",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Women's True Vintage": [
      "New With Tags",
      "New without Tags",
      "New with Defects",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Wrestling Figures": [
      "Mint",
      "Near Mint",
      "Good",
      "Fair",
      "Poor"
  ],
  "Wrestling Singles": [
      "Graded",
      "Raw - Near Mint or Better",
      "Raw - Excellent",
      "Raw - Very Good",
      "Raw - Poor"
  ],
  "Y2K": [
      "New With Tags",
      "New without Tags",
      "New with Defects",
      "Pre-owned - Excellent",
      "Pre-owned - Good",
      "Pre-owned - Fair",
      "Pre-owned - Damaged"
  ],
  "Yu-Gi-Oh! Cards": [
      "Graded",
      "New",
      "Mint",
      "Near Mint",
      "Light Played",
      "Moderately Played",
      "Heavily Played",
      "Damaged"
  ]
}
return conditionValues[category] || [];
}
export const shippingProfile = [
  "0-1 oz",
  "1-3 oz",
  "4-7 oz",
  "8-11 oz",
  "12-15 oz",
  "1 lb",
  "1-2 lbs",
  "2-3 lbs",
  "3-4 lbs",
  "4-6 lbs",
  "10-14 lbs",
  "Sports singles (3oz)",
  "0 to <100 grams",
  "100 to <250 grams",
  "250 to <500 grams",
  "500 to <750 grams",
  "750 grams to <1 KGs",
  "1 KGs to <2 KGs",
  "2 KGs to <3 KGs",
  "3 KGs to <5 KGs",
  "5 KGs to <7 KGs",
  "7 to <10 KGs",
];
export const requestOptions = [
  { label: "Site issues", value: "Site issues" },
  { label: "Subscriptions", value: "Subscriptions" },
  { label: "Billing", value: "Billing" },
  { label: "Exporting", value: "Exporting" },
  {
    label: "Liquidation Product Referrals",
    value: "Liquidation Product Referrals",
  },
];
export const getSubCategories = (category) => {
  const subCategoryMappings = {
    "Action Figures": [
      "Anime Figures",
      "DC Figures",
      "G.I. Joe Figures",
      "Hot Toys",
      "Antiques, Vintage & Ephemera",
      "Marvel Figures",
      "Other Action Figures",
      "Power Rangers",
      "Simpsons Figures",
      "Star Wars Figures",
      "TMNT Figures",
      "Transformers Figures",
      "Wrestling Figures",
    ],
    "Antiques, Vintage & Ephemera": [
      "Antiques",
      "Ephemera & Postcards",
      "Stamps",
      "Vintage Decor",
    ],

    "Arts & Handmade": [
      "Art & Prints",
      "Jewelry Making Supplies",
      "Knitting, Crochet & Fiber Arts",
      "Other General & Craft Supplies",
      "Artisan & Handmade Goods & Decor",
      "Quilting, Sewing & Fabric",
      "Handmade & Upcycled Fashion & Accessories",
    ],
    "Bags & Accessories": [
      "Fashion & Thrift Bags",
      "Luxury Bags & Accessories",
      "Other Accessories",
    ],
    Beauty: [
      "Fragrances",
      "Hair Products",
      "Makeup & Skincare",
      "Nails",
      "Other Beauty",
      "Skincare",
    ],
    Books: [
      "Antiquarian & Collectible Books",
      "Children's Books",
      "Graphic Novels",
      "Other Books",
      "Romance Books",
      "Sci-Fi, Fantasy, & Horror Books",
    ],
    "Coins & Money": [
      "Coins & Bullion",
      "Collectible Coins",
      "Other Coins & Money",
      "Paper Money & Currency",
    ],
    "Comics & Manga": [
      "Anime & Manga",
      "Vintage Comics",
      "Comic Art",
      "Modern Age",
      "Other Comics",
      "Pop Culture Memorabilia",
    ],
    Disneyana: [
      "Disney Accessories",
      "Disney Clothing",
      "Loungefly",
      "Disney Original & Animation Art",
      "Other Disneyana",
      "Disney Pins",
    ],
    Electronics: [
      "Cameras, Lenses & Drones",
      "Car Electronics",
      "Everyday Electronics",
      "Mechanical Keyboards",
      "Other Electronics",
      "PC Parts & Components",
    ],
    "Estate Sales & Storage Units": [
      "Estate Sales",
      "Garage Sales",
      "Other Estate Sales & Storage Units",
      "Return Pallets",
      "Storage Unit Finds",
    ],
    "Food & Drink": ["Coffee & Tea", "Other Food & Drink", "Snacks & Candy"],
    "Home & Garden": [
      "Home Decor",
      "Kitchen & Dining",
      "Other Home & Garden",
      "Plants & Garden",
      "Tools",
    ],
    Jewelry: [
      "Contemporary Costume",
      "Fine & Precious Metals",
      "Handcrafted & Artisan Jewelry",
      "Men's Jewelry",
      "Vintage & Antique Jewelry",
    ],
    Kawaii: [],
    "Baby & Kids": [],
    "Men's Fashion": [
      "Men's Modern",
      "Men's Vintage Clothing",
      "Other Men's Fashion",
    ],
    Movies: ["DVDs", "Movie Memorabilia", "VHS"],
    Music: [
      "CDs & Cassettes",
      "Instruments & Accessories",
      "Music Memorabilia",
      "Other Music",
      "Vinyl Records",
      "[DELETE] Instruments and Accessories",
    ],
    NFTs: [],
    "Rocks & Crystals": ["Crystals & Gems", "Fossils", "Other Rocks"],
    "Sneakers & Streetwear": ["Sneakers", "Streetwear"],
    "Sports Cards": [
      "Baseball Breaks",
      "Baseball Memorabilia",
      "Baseball Mystery",
      "Baseball Singles",
      "Basketball Breaks",
      "Basketball Memorabilia",
      "Basketball Mystery",
      "Basketball Singles",
      "F1 Cards",
      "Football Breaks",
      "Football Memorabilia",
      "Football Mystery",
      "Football Singles",
      "Hockey Breaks",
      "Hockey Mystery",
      "Hockey Singles",
      "NASCAR Cards",
      "Other Sports Memorabilia",
      "Other Sports Cards",
      "Soccer Breaks",
      "Soccer Memorabilia",
      "Soccer Mystery",
      "Soccer Singles",
      "UFC Breaks",
      "UFC Mystery",
      "UFC Singles",
      "Whatnot Sports Card Show",
      "Wrestling Breaks",
      "Wrestling Mystery",
      "Wrestling Singles",
    ],
    "Sporting Gear": [
      "Camping & Hiking",
      "Disc Golf",
      "Fishing",
      "Golf",
      "Hunting",
      "Other Sports Equipment",
    ],
    "Toys & Hobbies": [
      "Barbie",
      "Bearbrick",
      "Board Games",
      "D&D & Role-playing Games",
      "Diecast Cars",
      "Fast Food & Cereal Toys",
      "FigPin",
      "Funko Pop!",
      "Funko Soda",
      "Minifigures",
      "Models & Kits",
      "Monster High",
      "Other Designer Toys",
      "Other Diecast",
      "Other Dolls",
      "Other Funko Products",
      "Other LEGO",
      "Other Plush",
      "Other Tabletop Games",
      "Other Toys",
      "Puzzles",
      "Radio Control Vehicles & Toys",
      "Sets",
      "Slot Cars",
      "Squishmallows",
      "Vintage Toys",
      "Warhammer 40k & Wargames",
    ],
    "Trading Card Games": [
      "Akora",
      "DC Cards",
      "Digimon Cards",
      "Disney Cards",
      "Dragon Ball Cards",
      "Flesh & Blood",
      "Garbage Pail Kids",
      "Kryptik",
      "Magic: The Gathering",
      "Marvel Cards",
      "MetaZoo",
      "My Hero Academia Cards",
      "Naruto Cards",
      "One Piece Cards",
      "Kickstarter & Other Cards",
      "Pokémon Cards",
      "Star Wars Cards",
      "TCG Accessories",
      "Weiß Schwarz",
      "Yu-Gi-Oh! Cards",
    ],
    "Video Games": [
      "Consoles & Accessories",
      "Gamesgiving",
      "Modern Games",
      "Retro Games",
      "Strategy Guides, Manuals, Replacement Cases",
    ],
    Watches: [],
    "Women's Fashion": [
      "Other Women's Fashion",
      "Women's Activewear",
      "Women's Contemporary",
      "Women's True Vintage",
      "Y2K",
    ],
    "and Whatnot": [
      "Breweriana",
      "Cosplay Items",
      "Flags",
      "Magazines",
      "Maps",
      "Online 1-1 Experience",
      "Other",
      "Pet Supplies",
    ],
  };

  return subCategoryMappings[category] || [];
};
